/* You can add global styles to this file, and also import other style files */
@use "@angular/material" as mat;
@use "utils/scss/theme.scss" as theme;
@import "~quill/dist/quill.bubble.css";
@import "~quill/dist/quill.snow.css";

// ----- normal global css definitions -----
* {
  box-sizing: border-box;
}

html,
body,
app-root {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
}

body {
  margin: 0;
  color: theme.$light-primary-text;
  background-color: theme.$default-background-color;
}

app-root {
  display: flex;
  flex-direction: column;

  > .app-main-container {
    flex: 1;
  }
}

main {
  min-height: 100%;
}

h1 {
  color: theme.$primary;
  text-transform: uppercase;
}

h2 {
  text-transform: uppercase;
}

a {
  color: theme.$light-primary-text;
  cursor: pointer;
  transition: color 250ms ease-in-out;
  text-decoration: none;
}

p > a {
  text-decoration: underline;
  text-transform: uppercase;
}

a:hover,
a:focus {
  color: theme.get-property(primary, 200);
}

p {
  // max-width: 50rem;
}

.red-snackbar {
  // ToDo: Load color from theme file
  --mdc-snackbar-container-color: #e53935;
  --mat-snack-bar-button-color: #0165fc;
  color: white;
}

.green-snackbar {
  --mdc-snackbar-container-color: #39ca34;
  --mat-snack-bar-button-color: #0165fc;
  color: white;
}

.orange-snackbar {
  --mdc-snackbar-container-color: #f58011;
  --mat-snack-bar-button-color: #0165fc;
  color: white;
}
