// this file contains a custom angular theme with all its definitions
// some useful colors are also defined as variables to be directly use in the application
//
// Mixins:
//   `main-content-padding()` - adds media dependent padding, should be used on the main content container of as site
//
// Exports:
//   `$full-theme` - contains the full color-theme as required to pass to mat-mixins
//   `$primary`    - the primary color
//   `$accent`     - the accent color
//   `$text`       - the text color
//   `$background` - the background color
//   `get-property()`- a function to read any property from the theme

@use "sass:math";
@use "sass:map";
@use "src/utils/scss/typography.scss" as typography;
@use "@angular/material" as mat;

@include mat.core();

// Variables for theme
$dark-primary-text: rgba(black, 1);
$dark-secondary-text: rgba(black, 0.87);
$dark-hint-text: rgba(black, 0.74);
$dark-disabled-text: rgba(black, 0.54);
$dark-dividers: rgba(black, 0.38);
$dark-focused: rgba(black, 0.12);
$light-primary-text: #fff0e4;
$light-secondary-text: rgba(#fff0e4, 79);
$light-disabled-text: rgba(white, 0.53);
$light-dividers: rgba(white, 0.37);
$light-focused: rgba(white, 0.12);

$app-bar-color: #060709;
$default-background-color: #111319;
$default-hover-color: rgba(255, 255, 255, 0.04);
$default-disabled-button: rgba(255, 255, 255, 0.3);
$default-card-color: #1f222d;
$default-tooltip-color: #292e3d;

$jws-orange-palette: (
  50: #ffede1,
  100: #fed3b3,
  200: #feb581,
  300: #fe974e,
  400: #fd8128,
  500: #fd6b02,
  600: #fd6302,
  700: #fc5801,
  800: #fc4e01,
  900: #fc3c01,
  A100: #ffffff,
  A200: #fff2ef,
  A400: #ffc8bc,
  A700: #ffb3a2,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);

$jws-blue-palette: (
  50 : #e1f2ff,
  100 : #b3dffe,
  200 : #81cafe,
  300 : #4eb4fe,
  400 : #28a4fd,
  500 : #0294fd,
  600 : #028cfd,
  700 : #0181fc,
  800 : #0177fc,
  900 : #0165fc,
  A100 : #ffffff,
  A200 : #eff4ff,
  A400 : #bcd3ff,
  A700 : #a2c2ff,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
  )
);

$jws-warn-palette: (
  50: #ffebee,
  100: #ffcdd2,
  200: #ef9a9a,
  300: #e57373,
  400: #ef5350,
  500: #f44336,
  600: #e53935,
  700: #d32f2f,
  800: #c62828,
  900: #b71c1c,
  A100: #ff8a80,
  A200: #ff5252,
  A400: #ff1744,
  A700: #d50000,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  ),
);

$jws-info-palette: (
  50: #fef1e3,
  100: #fcdbb8,
  200: #fbc489,
  300: #f9ac5a,
  400: #f79a36,
  500: #f68813,
  600: #f58011,
  700: #f3750e,
  800: #f26b0b,
  900: #ef5806,
  A100: #ffffff,
  A200: #ffece4,
  A400: #ffc8b1,
  A700: #ffb797,
  contrast: (
      50: $dark-primary-text,
      100: $dark-primary-text,
      200: $dark-primary-text,
      300: $dark-primary-text,
      400: $dark-primary-text,
      500: $dark-primary-text,
      600: $dark-primary-text,
      700: $dark-primary-text,
      800: $dark-primary-text,
      900: $light-primary-text,
      A100: $dark-primary-text,
      A200: $dark-primary-text,
      A400: $dark-primary-text,
      A700: $dark-primary-text,
  )
);

$jws-success-palette: (
  50: #e8f9e7,
  100: #c5f1c4,
  200: #9fe79d,
  300: #79dd75,
  400: #5cd658,
  500: #3fcf3a,
  600: #39ca34,
  700: #31c32c,
  800: #29bd25,
  900: #1bb218,
  A100: #e7ffe6,
  A200: #b4ffb3,
  A400: #82ff80,
  A700: #69ff67,
  contrast: (
      50: $dark-primary-text,
      100: $dark-primary-text,
      200: $dark-primary-text,
      300: $dark-primary-text,
      400: $dark-primary-text,
      500: $dark-primary-text,
      600: $dark-primary-text,
      700: $dark-primary-text,
      800: $light-primary-text,
      900: $light-primary-text,
      A100: $dark-primary-text,
      A200: $dark-primary-text,
      A400: $dark-primary-text,
      A700: $dark-primary-text,
  )
);

// Background palette for light themes.
$jws-theme-background-palette: (
  status-bar:               $dark-primary-text,
  app-bar:                  $app-bar-color,
  background:               $default-background-color,
  hover:                    $default-hover-color,
  card:                     $default-card-color,
  dialog:                 white,
  disabled-button:          $light-focused,
  raised-button:            $default-background-color,
  focused-button:           $light-focused,
  selected-button:          $app-bar-color,
  selected-disabled-button: $default-card-color,
  disabled-button-toggle: black,
  unselected-chip:          $default-tooltip-color,
  disabled-list-option:     $light-focused,
  tooltip:                  $default-tooltip-color,
);

// Foreground palette for light themes.
$jws-theme-foreground-palette: (
  base:              $light-primary-text,
  divider:           $light-focused,
  dividers:          $light-focused,
  disabled:          $light-disabled-text,
  disabled-button:   $default-disabled-button,
  disabled-text:     $light-disabled-text,
  elevation:         black,
  hint-text:         $dark-hint-text,
  secondary-text:    $dark-secondary-text,
  icon:              $light-primary-text,
  icons:             $light-primary-text,
  text:              $light-primary-text,
  slider-min:        $light-primary-text,
  slider-off:        $default-disabled-button,
  slider-off-active: $default-disabled-button,
);

$primary-theme: mat.define-palette($jws-orange-palette);
$accent-theme: mat.define-palette($jws-blue-palette);
$warn-theme: mat.define-palette($jws-warn-palette);
$info-theme: mat.define-palette($jws-info-palette);
$success-theme: mat.define-palette($jws-success-palette);

$typography-config: mat.define-typography-config(
  $headline-1: mat.define-typography-level(112px, 112px, 300, $letter-spacing: -0.05em),
  $headline-2: typography.$jwspb-title,
  $headline-3: typography.$jwspb-subheading-2,
  $headline-4: typography.$jwspb-subheading-1,
  $headline-5: typography.$jwspb-headline,
  $headline-6: typography.$jwspb-title,
  $subtitle-1: typography.$jwspb-subheading-2,
  $subtitle-2: typography.$jwspb-subheading-1,
  $body-2: typography.$jwspb-body-1,
  $body-1: typography.$jwspb-subheading-1,
  $caption: typography.$jwspb-caption,
  $button: typography.$jwspb-button
);

$jws-theme: mat.define-light-theme((
  color: (
    primary: $primary-theme,
    accent: $accent-theme,
  ),
  typography: $typography-config,
  density: 0,
));

@include mat.all-component-themes($jws-theme);
@include mat.typography-hierarchy($typography-config);

// useful mixins for global design:
@mixin main-content-padding(
  $block: 4rem,
  $inline: 4rem,
  $breakpoint: 1000px,
  $mobile-factor: math.div(3, 8),
  $modify-block: true,
  $modify-inline: true
) {
  padding: $block $inline;
  @if $modify-block and $modify-inline {
      @media (max-width: $breakpoint) {
        padding: ($block * $mobile-factor) ($inline * $mobile-factor);
      }
  } @else {
    @if $modify-block {
      @media (max-width: $breakpoint) {
        padding: ($block * $mobile-factor) $inline;
      }
    }
    @if $modify-inline {
      @media (max-width: $breakpoint) {
        padding: $block ($inline * $mobile-factor);
      }
    }
  }
}


// global variables for ease of use
$primary: map.get($jws-orange-palette, 600);
$accent: map.get($jws-blue-palette, 600);
$error: map.get($jws-warn-palette, 600);
$success: map.get($jws-success-palette, 600);
$info: map.get($jws-info-palette, 600);

$navbar-height: 10vh;


@function get-property($type, $prop) {
  // this function allows the user to get singular properties from the $_full-theme map
  // you can for example use get-property(background, background) to get the background color from the background pallet

  $type-map: map-get($jws-theme, $type);
  @if $type-map == null {
    @error "color type '#{$type}' doesn't exist in $jws-theme, please use a valid pallet name";
  }

  $property: map-get($type-map, $prop);
  @if $property == null {
    @error "property '#{$prop}' '#{$type-map}' doesn't exist in pallet, please use a valid property name";
  }

  @return $property;
}
